import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import TwitterIcon from "./icons/TwitterIcon";

const getSocials = (links: {
  facebook: string;
  instagram: string;
  twitter: string;
}) => [
  {
    name: "Facebook",
    href: links.facebook,
    icon: (props: any) => <FacebookIcon {...props} />,
  },
  {
    name: "Twitter",
    href: links.twitter,
    icon: (props: any) => <TwitterIcon {...props} />,
  },
  {
    name: "Instagram",
    href: links.instagram,
    icon: (props: any) => <InstagramIcon {...props} />,
  },
];

export interface SocialsProps {
  isVertical?: boolean;
}

const Socials = ({ isVertical = false }: SocialsProps) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          links {
            facebook
            instagram
            twitter
          }
        }
      }
    }
  `);

  const siteMetadata = data.site.siteMetadata;

  return (
    <div
      className={`inline-flex flex-grow ${
        isVertical
          ? "flex-col justify-center space-y-6"
          : "items-center justify-between space-x-6"
      }`}
    >
      {getSocials(siteMetadata.links).map((item) => (
        <a
          key={item.name}
          href={item.href}
          target="_blank"
          rel="noreferrer noopener"
          className="hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="sr-only">{item.name}</span>
          <item.icon className="h-4 w-4" aria-hidden="true" />
        </a>
      ))}
    </div>
  );
};

export default Socials;
