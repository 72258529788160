import * as React from "react";

import "react-toastify/dist/ReactToastify.min.css";

export interface ShellProps {
  children: React.ReactNode;
}

const Shell = ({ children }: ShellProps) => {
  return (
    <main className="relative bg-gray-100">
      <div id="recaptcha-container-id"></div>
      {/* <Script src="../scripts/dark-mode" /> */}
      <div className="sticky left-0 top-0 z-20 w-full">{/* <Header /> */}</div>
      {children}
    </main>
  );
};

export default Shell;
